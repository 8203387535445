import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import './assets/scss/bootstrap-custom.scss' 
import './assets/styles/main.css'
import router from './router'
import VueGtag from 'vue-gtag'

const pinia = createPinia()
const app = createApp(App)
app.use(pinia)
app.use(router)
app.mount('#app')

if (process.env.NODE_ENV === "production") {
  app.use(
    VueGtag, { config: { id: "G-068DM57WT7" }, }, router
  );
}
////createApp(App).mount('#app')