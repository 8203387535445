<template>
	<footer id="footer" class="bg-head">
		<div class="ft_links">
			<div class="links">
			<dl>
			<dt>
			<router-link to="/about">このサイトについて</router-link>
			</dt>
			</dl>
			<dl>
			<dt>
			<router-link to="/">今日の夕飯</router-link>
			</dt>
			</dl>
			<dl>
			<dt>
			<router-link to="/ranking">みんなの夕飯</router-link>
			</dt>
			</dl>
			<dl>
			<dt>
			<router-link to="/sitemap">サイトマップ</router-link>
			</dt>
			</dl>
			<dl>
			<dt>
			<router-link to="/term">利用規約</router-link>
			</dt>
			</dl>
			<dl>
			<dt>
			<a href="https://twitter.com/kyonoyuhan_jp" target="_blank">公式Twitter</a>
			</dt>
			</dl>
			</div>
		</div>
		<div class="copy">
			Copyright (C) KyoNoYuhan.jp All Rights Reserved.
		</div>
	</footer>
</template>