<template>
  <div class="bg">
    <div class="container pt-4 pd-1">
      <div class="d-grid gap-2 col-10 mx-auto text-dark text-center bg-white border border-dark border-4 box-shadow">
        <div class="h1 fw-bold p-3 m-0">サイトマップ</div>
      </div>
    </div><!-- /.container -->
    <div class="container pt-4 pb-4">
      <div class="list-group">
        <div class="d-grid gap-2 col-10 mx-auto text-dark text-start bg-white border border-dark border-4 box-shadow">
          <router-link to="/about" class="text-dark px-3 pt-3 fw-bold fs-5 text-decoration-underline">このサイトについて</router-link>
          <router-link to="/" class="text-dark px-3 fw-bold fs-5 text-decoration-underline">今日の夕飯</router-link>
          <router-link to="/ranking" class="text-dark px-3 fw-bold fs-5 text-decoration-underline">みんなの夕飯</router-link>
          <router-link to="/sitemap" class="text-dark px-3 fw-bold fs-5 text-decoration-underline">サイトマップ</router-link>
          <router-link to="/term" class="text-dark px-3 pb-3 fw-bold fs-5 text-decoration-underline">利用規約</router-link>
        </div>
      </div>
    </div><!-- /.container -->
  </div><!-- /.bg -->
</template>

<script>
export default {
  emits: ['updateLoading'], 
  props: { isLoading: Boolean },
  components: {
  },
}
</script>