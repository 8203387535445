<template>
  <div class="bg">
    <div class="container pt-4">
      <div class="d-grid gap-2 col-10 mx-auto text-dark text-center bg-white border border-dark border-4 box-shadow">
        <div class="h1 fw-bold p-3 m-0">サービス概要</div>
      </div>
    </div><!-- /.container -->
    <div class="container pt-4">
      <div class="d-grid gap-2 col-10 mx-auto text-dark text-start bg-white border border-dark border-4 box-shadow">
        <h1 class="display-6 px-3 pt-3 pb-0">今日の夕飯どうしよう？</h1>
        <p class="px-4 mb-0 fw-bold fs-5">毎日のお悩みを「今日の夕飯.jp」が解決。<br />
        AIが様々なメニューの中からあなたの「今日の夕飯」を選び出します。</p>
        <p class="px-4 mb-0">AIは良く選ばれているメニューやあなたの食べたメニューなど様々な要素からあなたの「今日の夕飯」をおすすめします。<br />
        もちろん、気分と違う場合は、AIに考え直してもらうことも可能です。<br />
        今日の夕飯を決めて、家族にシェアしたり、ネットで注文や近くのお店を探すこともすぐできます。</p>
        <p class="px-4 mb-3 fw-bold fs-5">「今日の夕飯.jp」を使って、今日の夕飯を用意する手間を減らしましょう！</p>
      </div>
    </div><!-- /.container -->
    <div class="container pt-4">
      <div class="d-grid gap-2 col-10 mx-auto text-dark text-center bg-white border border-dark border-4 box-shadow">
        <div class="h1 fw-bold p-3 m-0">お知らせ</div>
      </div>
      <div class="list-group pt-4">
        <div class="d-grid gap-2 col-10 mx-auto text-dark text-start bg-white border border-dark border-4 box-shadow">
          <p class="text-dark p-3 mb-0 fw-bold fs-5">2023.05.22 サービスを開始しました。</p>
        </div>
      </div>
    </div><!-- /.container -->
    <div class="container pt-4">
      <div class="d-grid gap-2 col-10 mx-auto text-dark text-center bg-white border border-dark border-4 box-shadow">
        <div class="h1 fw-bold p-3 m-0">連絡先</div>
      </div>
      <div class="py-4">
        <div class="d-grid gap-2 col-10 mx-auto text-dark text-start bg-white border border-dark border-4 box-shadow">
          <span class="text-dark p-3">メールアドレス：whats.for.dinner.today.jp@gmail.com</span>
        </div>
      </div>
    </div><!-- /.container -->
  </div><!-- /.bg -->
</template>

<script>
export default {
  emits: ['updateLoading'], 
  props: { isLoading: Boolean },
  components: {
  },
}
</script>
