<template>
  <div class="bg">
    <div class="container py-4 pd-1">
      <div class="d-grid gap-2 col-10 mx-auto text-dark text-center bg-white border border-dark border-4 box-shadow">
        <div class="h4 fw-bold p-3 m-0">お探しのページが見つかりませんでした</div>
        <div class="h6 pb-3 m-0">
        <router-link to="/" class="text-dark text-decoration-underline">TOPページ</router-link>へお戻りください。
        </div>
      </div>
    </div><!-- /.container -->
  </div><!-- /.bg -->
</template>

<script>
export default {
  emits: ['updateLoading'], 
  props: { isLoading: Boolean },
  components: {
  },
}
</script>