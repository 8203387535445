<template>
    <nav class="navbar navbar-expand-lg navbar-dark bg-head">
    <div class="container-fluid">
      <router-link class="navbar-brand" to="/" @click="closeNavbar"><img src="@/assets/images/logo.png" style="width:240px;"></router-link>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarToggler" aria-controls="navbarToggler" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarToggler">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <router-link class="nav-link text-end" to="/about" @click="closeNavbar">このサイトについて</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link text-end" to="/" @click="closeNavbar">今日の夕飯</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link text-end" to="/ranking" @click="closeNavbar">みんなの夕飯</router-link>
          </li>
          <li class="nav-item">
            <a class="nav-link text-end" href="https://twitter.com/kyonoyuhan_jp" target="_blank" @click="closeNavbar">公式Twitter</a>
          </li>
        </ul>
      </div>
    </div>
    </nav>
</template>
<script>
export default {
  methods: {
    closeNavbar() {
      document.querySelector('.navbar-collapse').classList.remove('show');
    }
  }
}
</script>