<template>
  <div class="bg">
    <div class="jumbotron jumbotron-fluid top-image" style="padding: 1rem;">
      <div class="slide-image"></div>
      <div class="slide-image"></div>
      <div class="slide-image"></div>
      <div class="container py-4 text-dark top-text">
        <h1 class="display-5 pd-3 top-underline">今日の夕飯どうしよう？</h1>
        <p class="lead">毎日のお悩みを「今日の夕飯.jp」が解決。</p>
        <p class="lead">AIが様々なメニューの中から</p>
        <p class="lead">あなたの「今日の夕飯」を選び出します。</p>
        <div class="text-end d-flex justify-content-end px-4">
          <button @click="menuSelect()" class="fs-5 fw-bold text-dark text-center bg-white border border-dark border-4 balloon_top">
          使ってみる<div class="arrow"></div>
          </button>
        </div>
      </div><!-- /.container -->
    </div>
    <div class="container pt-3 pb-1">
      <button @click="menuSelect()" @animationend="loopAnime()" class="d-grid gap-2 col-10 mx-auto fs-3 fw-bold text-dark text-center bg-white border border-dark border-4 balloon_l init-anime anime-delay-1 loop-anime-1" type="button">
      本日のおすすめはコレ！<div class="arrow"></div>
      </button>
    </div><!-- /.container -->
    <div class="container pt-4 pb-1">
      <router-link to="/ranking" class="d-grid gap-2 col-10 mx-auto fs-3 fw-bold text-dark text-center bg-white border border-dark border-4 balloon_r init-anime anime-delay-2">
      みんなの夕飯はコチラ<div class="arrow"></div>
      </router-link>
    </div><!-- /.container -->

    <div class="container pt-4">
      <div class="d-grid gap-2 col-10 mx-auto text-dark text-center bg-white border border-dark border-4 box-shadow init-anime anime-delay-3">
        <div class="h3 fw-bold p-3 m-0">今日の夕飯.jpってなに？</div>
      </div>
    </div><!-- /.container -->
    <div class="container pt-4">
      <div class="d-grid gap-2 col-10 mx-auto text-dark text-start bg-white border border-dark border-4 box-shadow init-anime anime-delay-4">
        <p class="px-4 mt-3 mb-1 fw-bold fs-5">AIが様々なメニューの中からあなたの「今日の夕飯」を選び出します。</p>
        <p class="px-4 mb-0">もちろん、気分と違う場合は、AIに考え直してもらうことも可能です。<br />
        今日の夕飯を決めて、家族にシェアしたり、ネットで注文や近くのお店を探すこともすぐできます。</p>
        <p class="px-4 mt-2 mb-3 fw-bold fs-5">「今日の夕飯.jp」を使って、今日の夕飯を用意する手間を減らしましょう！</p>
      </div>
    </div><!-- /.container -->
    <div class="container py-4">
      <FlexBanner></FlexBanner>
    </div><!-- /.container -->
  </div><!-- /.bg -->
</template>

<script>
import FlexBanner from "@/components/FlexBanner"
import { selectMenuStore } from "@/stores/menu"
const apiUrl = process.env.VUE_APP_API_URL 
export default {
  props: {
    isLoading: Boolean
  },
  components: {
    FlexBanner,
  },
  data () {
    return {
    }
  },
  methods: {
    updateLoadingOn() {
      this.$emit('update-loading', true);
    },
    updateLoadingOff() {
      this.$emit('update-loading', false);
    },
    loopAnime() {
      document.querySelector('.loop-anime-1').classList.replace('init-anime','loop-anime');
    },
    menuSelect() {
      const store = selectMenuStore();
      this.updateLoadingOn()
      fetch(apiUrl+'menuselect', {credentials: 'include'})
      .then( response => {
        this.updateLoadingOff()
        return response.json()
      })
      .then( json => {
        this.msg = json.message
        this.menu_id = json.menu_id
        this.menu_name = json.menu_name
        this.menu_text = json.menu_text
        this.menu_image = json.menu_image

        store.$patch({
          menu_id: json.menu_id,
          menu_name: json.menu_name,
          menu_text: json.menu_text,
          menu_image: json.menu_image,
        });

        this.$router.push({ name: "MenuSelect", query: { menu_id: this.menu_id }})
      })
      .catch( (err) => {
        this.msg = err
        alert('エラーが発生しました。')
        location.reload()
      });
    },
  },
  created () {
    const store = selectMenuStore();
    store.$reset()
    fetch(apiUrl+'userid', {credentials: 'include'})
    .then( response => {
      return response.json()
    })
    .then( json => {
      this.msg = json.message
    })
    .catch( (err) => {
      this.msg = err
    });
  }
}
</script>