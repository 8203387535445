import { createRouter, createWebHistory } from 'vue-router'
import AppTop from './components/pages/AppTop.vue'
import MenuSelect from './components/pages/MenuSelect.vue'
import MenuResult from './components/pages/MenuResult.vue'
import MenuRanking from './components/pages/MenuRanking.vue'
import AboutUs from './components/pages/AboutUs.vue'
import TermsOfService from './components/pages/TermsOfService.vue'
import SiteMap from './components/pages/SiteMap.vue'
import NotFound from './components/pages/NotFound.vue'

const routes = [
  { path: '/', name: 'AppTop', component: AppTop },
  { path: '/select', name: 'MenuSelect', component: MenuSelect },
  { path: '/result', name: 'MenuResult', component: MenuResult },
  { path: '/ranking', name: 'MenuRanking', component: MenuRanking },
  { path: '/about', name: 'AboutUs', component: AboutUs },
  { path: '/term', name: 'TermsOfService', component: TermsOfService },
  { path: '/sitemap', name: 'SiteMap', component: SiteMap },
  { path: '/:catchAll(.*)', name: 'NotFound', component: NotFound },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router