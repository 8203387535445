<template>
  <div>
    <VueLoadingOverlay :active="isLoading" :is-full-page="true"></VueLoadingOverlay>
    <div>
      <MenuHeader></MenuHeader>
    </div>
    <div>
      <router-view :is-loading="isLoading" @update-loading="updateLoading"/>
    </div>
    <div>
      <MenuFooter></MenuFooter>
    </div>
  </div>
</template>

<script>
import MenuHeader from "@/components/MenuHeader"
import MenuFooter from "@/components/MenuFooter"
import VueLoadingOverlay from 'vue3-loading-overlay';
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';

export default {
  data() {
    return {
      isLoading: false
    }
  },
  components: {
    MenuHeader,
    MenuFooter,
    VueLoadingOverlay: VueLoadingOverlay,
  },
  methods: {
    updateLoading(isLoading) {
      this.isLoading = isLoading;
    }
  },
  created() {
  }
}
</script>
