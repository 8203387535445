<template>
  <div class="bg">
    <div class="container pt-4">
      <div class="d-grid gap-2 col-10 mx-auto text-dark text-center bg-white border border-dark border-4 box-shadow">
        <div class="h1 fw-bold p-3 m-0">みんなの夕飯ランキング</div>
      </div>
    </div><!-- /.container -->
    <div v-for="item in rankList" v-bind:key="item.ranking" class="container pt-4 pd-1">
      <div class="d-grid gap-2 col-10 mx-auto" style="position:relative;">
      <router-link :to="{ path: 'result', query: { menu_id: item.menu_id } }">
        <div class="d-grid gap-2 mx-auto text-dark border border-dark border-4 box-shadow ranking-image init-anime" v-bind:class="'anime-delay-'+item.ranking" :style="{ backgroundImage: 'url(' + item.imgsrc + ')' }" style="z-index:1;">
        <p class="d-grid gap-2 col-10 mx-auto text-center display-5 lead ranking-text" style="z-index:2;">
        <span v-if="item.ranking == 1">一位 {{ item.menu_name }}</span>
        <span v-else-if="item.ranking == 2">二位 {{ item.menu_name }}</span>
        <span v-else-if="item.ranking == 3">三位 {{ item.menu_name }}</span>
        <span v-else-if="item.ranking == 4">四位 {{ item.menu_name }}</span>
        <span v-else-if="item.ranking == 5">五位 {{ item.menu_name }}</span>
        </p><div class="arrow"></div>
        </div>
      </router-link>
      </div>
    </div><!-- /.container -->
    <div class="container pt-4 pd-1">
      <div class="row justify-content-center">
        <button @click="tweet()" class="d-grid gap-2 col-5 fs-5 fw-bold text-dark text-center bg-white border border-dark border-4 balloon_lu init-anime anime-delay-1" type="button">
        Twitterで共有<div class="arrow_lu"></div>
        </button>
      </div>
    </div><!-- /.container -->
    <div class="container pt-4">
      <FlexBanner></FlexBanner>
    </div><!-- /.container -->
    <div class="container pt-4">
      <div class="d-grid gap-2 col-10 mx-auto text-dark text-center bg-white border border-dark border-4 box-shadow">
        <div class="h1 fw-bold p-3 m-0">最近のみんなの夕飯</div>
      </div>
    </div><!-- /.container -->
    <div v-for="item in newList" v-bind:key="item.ranking" class="container pt-4 pd-1">
      <div class="d-grid gap-2 col-10 mx-auto" style="position:relative;">
      <router-link :to="{ path: 'result', query: { menu_id: item.menu_id } }">
        <div class="d-grid gap-2 mx-auto text-dark border border-dark border-4 box-shadow ranking-image init-anime" v-bind:class="'anime-delay-'+item.id" :style="{ backgroundImage: 'url(' + item.imgsrc + ')' }" style="z-index:1;">
        <p class="d-grid gap-2 col-10 mx-auto text-center display-5 lead ranking-text" style="z-index:2;">
        {{ item.menu_name }}
        </p><div class="arrow"></div>
        </div>
      </router-link>
      </div>
    </div><!-- /.container -->
    <div class="container py-4">
      <FlexBanner></FlexBanner>
    </div><!-- /.container -->
  </div><!-- /.bg -->
</template>

<script>
import FlexBanner from "@/components/FlexBanner"
const menuImages = require.context('@/assets/images/menu', true, /\.png$/)
const apiUrl = process.env.VUE_APP_API_URL 
export default {
  components: {
    FlexBanner,
  },
  data () {
    return {
      msg: "",
      createLoaded: false,
      rankList: [],
      newList: [],
    }
  },
  methods: {
    updateLoadingOn() {
      this.$emit('update-loading', true);
    },
    updateLoadingOff() {
      this.$emit('update-loading', false);
    },
    tweet() {
      const baseUrl = 'https://twitter.com/intent/tweet'
      window.open(`${baseUrl}?text=${this.tweet_text}&url=${this.tweet_url}&hashtags=今日の夕飯`)
    },
  },
  async created () {
    this.updateLoadingOn();
    try {
      const response = await fetch(apiUrl+`rank`, { credentials: 'include' });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const json = await response.json();
      if (!json.newList || !json.rankList) {
        throw new Error('JSON payload is missing required properties.');
      }
      for (let index = 0; index < json.newList.length; index++) {
        const tmp = json.newList[index]
        tmp.imgsrc = menuImages(json.newList[index].menu_image)
        this.newList[index] = tmp;
      }
      for (let index = 0; index < json.rankList.length; index++) {
        const tmp = json.rankList[index]
        tmp.imgsrc = menuImages(json.rankList[index].menu_image)
        this.rankList[index] = tmp;
      }
      // rankingText
      this.rankingText =''
      for (let index = this.rankList.length - 1; index >= 0;  index--) {
        this.rankingText = this.rankingText + '第' + this.rankList[index].ranking + '位 ' + this.rankList[index].menu_name + '%0a'
      }
      this.createLoaded = true;
      this.tweet_url = 'https://kyonoyuhan.jp/ranking'
      this.tweet_text = '今日の夕飯.jpランキング！%0a今もっとも食べられている夕飯は・・・%0a%0a' + this.rankingText + '%0aあなたへのおすすめの夕飯はこちらから！'
    } catch (error) {
      this.msg = error.message;
      alert('エラーが発生しました。')
      location.reload()
    }
    this.updateLoadingOff();
  }
}
</script>
