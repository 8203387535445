import { defineStore } from 'pinia';

export const selectMenuStore = defineStore('selectMenu', {
  state: () => ({
    menu_id: 0,
    menu_name: '',
    menu_text: '',
    menu_image: '',
  }),
});
