<template>
  <div v-show="createLoaded" class="bg">
    <div class="container pt-4">
      <div class="d-grid gap-2 col-10 mx-auto fs-3 fw-bold text-dark text-center bg-white border border-dark border-4 balloon_l init-anime anime-delay-1" style="z-index:2;">
      今日の夕飯はこれだ！
      </div>
      <div class="d-grid gap-2 col-10 mx-auto" style="position:relative;">
      <img :src="menu_image" class="d-grid gap-2 col-10 mx-auto border border-dark border-4 img-fluid menu-img" style="z-index:1;" alt="メニュー画像">
      <img src="@/assets/images/underline.png" class="d-grid gap-2 col-10 mx-auto img-fluid underline" style="z-index:2;" alt="underline">
        <div class="d-grid gap-2 col-10 mx-auto text-center display-2 lead menu-text" style="z-index:3;">
          <div>
          <span v-for="(char, index) in menu_name" :key="index" class="menu-anime" v-bind:class="'anime-delay-'+index">{{ char }}</span>
          </div>
        </div>
      </div>
    </div><!-- /.container -->
    <div class="container pt-4 pd-1">
      <div class="d-grid gap-2 col-10 mx-auto fs-6 fw-bold text-dark text-left bg-white border border-dark border-4 balloon_lu init-anime anime-delay-2" style="z-index:4;">
      {{ menu_text }}
      </div>
    </div><!-- /.container -->
    <div class="container pt-4">
      <FlexBanner></FlexBanner>
    </div><!-- /.container -->
    <div class="container pt-4 pd-1">
      <div class="row justify-content-center">
        <button @click="tweet()" class="d-grid gap-2 col-5 fs-5 fw-bold text-dark text-center bg-white border border-dark border-4 balloon_l init-anime anime-delay-4" type="button">
        Twitterで共有<div class="arrow"></div>
        </button>
        <button @click="line()" class="d-grid gap-2 col-5 fs-5 fw-bold text-dark text-center bg-white border border-dark border-4 balloon_r init-anime anime-delay-4" type="button">
        LINEで共有<div class="arrow"></div>
        </button>
      </div>
    </div><!-- /.container -->
    <div class="container pt-4 pd-1">
      <div class="row justify-content-center">
        <button @click="googleMap()" class="d-grid gap-2 col-5 fs-5 fw-bold text-dark text-center bg-white border border-dark border-4 balloon_l init-anime anime-delay-5" type="button">
        近くのお店を探す<div class="arrow"></div>
        </button>
        <button @click="cookpad()" class="d-grid gap-2 col-5 fs-5 fw-bold text-dark text-center bg-white border border-dark border-4 balloon_r init-anime anime-delay-5" type="button">
        レシピを探す<div class="arrow"></div>
        </button>
      </div>
    </div><!-- /.container -->
    <div class="container py-4 pd-1">
      <router-link to="/ranking" class="d-grid gap-2 col-10 mx-auto fs-3 fw-bold text-dark text-center bg-white border border-dark border-4 balloon_l init-anime anime-delay-6">
      みんなの夕飯はコチラ<div class="arrow"></div>
      </router-link>
    </div><!-- /.container -->
    <div class="container py-4">
      <FlexBanner></FlexBanner>
    </div><!-- /.container -->
  </div><!-- /.bg -->
</template>

<script>
import FlexBanner from "@/components/FlexBanner"
import { selectMenuStore } from "@/stores/menu"
const menuImages = require.context('@/assets/images/menu', true, /\.png$/)
const apiUrl = process.env.VUE_APP_API_URL 
export default {
  components: {
    FlexBanner,
  },
  data () {
    return {
      createLoaded: false,
      menu_id: '',
      menu_name: '',
      menu_text: '',
      menu_image: '',
    }
  },
  methods: {
    updateLoadingOn() {
      this.$emit('update-loading', true);
    },
    updateLoadingOff() {
      this.$emit('update-loading', false);
    },
    loopAnime() {
      document.querySelector('.loop-anime-1').classList.replace('init-anime','loop-anime');
    },
    demaeCan() {
      const baseUrl = 'https://demae-can.com/search/13101055001'
      window.open(`${baseUrl}?q=${this.menu_name}`)
    },
    tweet() {
      const baseUrl = 'https://twitter.com/intent/tweet'
      window.open(`${baseUrl}?text=${this.tweet_text}&url=${this.tweet_url}&hashtags=今日の夕飯`)
    },
    line() {
      const baseUrl = 'https://social-plugins.line.me/lineit/share'
      window.open(`${baseUrl}?url=${this.tweet_url}&text=${this.tweet_text}`)
    },
    googleMap() {
      const baseUrl = 'https://www.google.com/maps/search'
      window.open(`${baseUrl}/?api=1&query=${this.menu_name}`)
    },
    cookpad() {
      const baseUrl = 'https://cookpad.com/search'
      window.open(`${baseUrl}/${this.menu_name}`)
    },
  },
  async created () {
    const store = selectMenuStore(); 
    if (store.menu_id != 0) {
      this.menu_id = store.menu_id;
      this.menu_name = store.menu_name;
      this.menu_text = store.menu_text;
      this.menu_image = menuImages(store.menu_image);
      this.createLoaded = true;
      this.tweet_text = '今日の夕飯は『' + this.menu_name + '』に決まり！' 
      this.tweet_url = 'https://kyonoyuhan.jp/result/?menu_id=' + this.menu_id
    } else {    
      try {
        const params = new URLSearchParams(this.$route.query);
        this.menu_id = params.get('menu_id');
        const response = await fetch(apiUrl+`menu?menuid=${this.menu_id}`, { credentials: 'include' });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const json = await response.json();
        if (!json.menu_id || !json.menu_name || !json.menu_text || !json.menu_image) {
          throw new Error('JSON payload is missing required properties.');
        }
        this.menu_id = json.menu_id;
        this.menu_name = json.menu_name;
        this.menu_text = json.menu_text;
        this.menu_image = menuImages(json.menu_image);
        this.createLoaded = true;
        this.tweet_text = '今日の夕飯は『' + this.menu_name + '』に決まり！' 
        this.tweet_url = 'https://kyonoyuhan.jp/result/?menu_id=' + this.menu_id
      } catch (error) {
        this.msg = error.message;
        alert('エラーが発生しました。')
        location.reload()
      }
    }
    store.$reset()
  }
}
</script>
