<template>
  <div class="d-flex flex-wrap justify-content-center">
    <div v-for="id in banners" :key="id" class="flex-md-row flex-column">
      <div v-if="id==1" class="d-block mx-auto py-1 px-3">
        <a href="https://click.linksynergy.com/fs-bin/click?id=98hNTCuH2Ow&offerid=1126791.5&type=4&subid=0"><img alt="すかいらーくの宅配" border="0" src="https://www.skylark.co.jp/site_resource/roomservice/roomservicelogo_234_60.jpg"></a><img border="0" width="1" height="1" src="https://ad.linksynergy.com/fs-bin/show?id=98hNTCuH2Ow&bids=1126791.5&type=4&subid=0">
      </div>
      <div v-if="id==2" class="d-block mx-auto py-1 px-3">
        <a href="https://px.a8.net/svt/ejp?a8mat=3TAVQB+42GNLE+4R6I+61Z81" rel="nofollow"><img border="0" width="234" height="60" alt="" src="https://www28.a8.net/svt/bgt?aid=230615075246&wid=001&eno=01&mid=s00000022185001017000&mc=1"></a><img border="0" width="1" height="1" src="https://www19.a8.net/0.gif?a8mat=3TAVQB+42GNLE+4R6I+61Z81" alt="">
      </div>
      <div v-if="id==3" class="d-block mx-auto py-1 px-3">
        <a href="https://px.a8.net/svt/ejp?a8mat=3TAVQB+419SDU+1OK+O7P69" rel="nofollow"><img border="0" width="234" height="60" alt="" src="https://www26.a8.net/svt/bgt?aid=230615075244&wid=001&eno=01&mid=s00000000218004067000&mc=1"></a><img border="0" width="1" height="1" src="https://www10.a8.net/0.gif?a8mat=3TAVQB+419SDU+1OK+O7P69" alt="">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
  },
  data () {
    return {
      banners: [1,2,3],
    }
  },
  methods: {
    shuffleBanner() {
      for (let i = this.banners.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [this.banners[i], this.banners[j]] = [this.banners[j], this.banners[i]];
      }
    },
  },
  mounted () {
    this.shuffleBanner();
  }
}
</script>