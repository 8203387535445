<template>
  <div v-show="createLoaded" class="bg">
    <div class="container pt-4">
      <div class="d-grid gap-2 col-10 mx-auto fs-3 fw-bold text-dark text-center bg-white border border-dark border-4 balloon_l init-anime anime-delay-1" style="z-index:2;">
      今日の夕飯はこれ……？
      </div>
      <div class="d-grid gap-2 col-10 mx-auto" style="position:relative;">
      <img :src="menu_image" class="d-grid gap-2 col-10 mx-auto border border-dark border-4 img-fluid menu-img" style="z-index:1;" alt="メニュー画像">
      <img src="@/assets/images/underline.png" class="d-grid gap-2 col-10 mx-auto img-fluid underline" style="z-index:2;" alt="underline">
        <div class="d-grid gap-2 col-10 mx-auto text-center display-2 lead menu-text" style="z-index:3;">
          <div>
          <span v-for="(char, index) in menu_name" :key="index" class="menu-anime" v-bind:class="'anime-delay-'+index">{{ char }}</span>
          </div>
        </div>
      </div>
    </div><!-- /.container -->
    <div class="container pt-4 pd-1">
      <div class="d-grid gap-2 col-10 mx-auto fs-6 fw-bold text-dark text-left bg-white border border-dark border-4 balloon_lu init-anime anime-delay-2" style="z-index:4;">
      {{ menu_text }}
      </div>
    </div><!-- /.container -->
    <div class="container py-4 pd-1">
      <div class="row justify-content-center">
        <button @click="menuSelect()" @animationend="loopAnime()" class="d-grid gap-2 col-5 fs-3 fw-bold text-dark text-center bg-white border border-dark border-4 balloon_l init-anime anime-delay-3 loop-anime-1" type="button">
        違うな…<div class="arrow"></div>
        </button>
        <button @click="menuFix()" @animationend="loopAnime()" class="d-grid gap-2 col-5 fs-3 fw-bold text-dark text-center bg-white border border-dark border-4 balloon_r init-anime anime-delay-4 loop-anime-2" type="button">
        これだ！<div class="arrow"></div>
        </button>
      </div>
    </div><!-- /.container -->
    <div class="container py-4">
      <FlexBanner></FlexBanner>
    </div><!-- /.container -->
  </div><!-- /.bg -->
</template>

<script>
import FlexBanner from "@/components/FlexBanner"
import { selectMenuStore } from "@/stores/menu"
const menuImages = require.context('@/assets/images/menu', true, /\.png$/)
const apiUrl = process.env.VUE_APP_API_URL 
export default {
  data () {
    return {
      createLoaded: false,
      menu_id: '',
      menu_name: '',
      menu_text: '',
      menu_image: '',
    }
  },
  components: {
    FlexBanner,
  },
  methods: {
    updateLoadingOn() {
      this.$emit('update-loading', true);
    },
    updateLoadingOff() {
      this.$emit('update-loading', false);
    },
    loopAnime() {
      document.querySelector('.loop-anime-1').classList.replace('init-anime','loop-anime');
      document.querySelector('.loop-anime-2').classList.replace('init-anime','loop-anime');
    },
    menuSelect() {
      const store = selectMenuStore();
      this.updateLoadingOn()
      fetch(apiUrl+'menuselect', {credentials: 'include'})
      .then( response => {
        this.updateLoadingOff()
        return response.json()
      })
      .then( json => {
        this.msg = json.message
        this.menu_id = json.menu_id
        this.menu_name = json.menu_name
        this.menu_text = json.menu_text
        this.menu_image = menuImages(json.menu_image)

        store.$patch({
          menu_id: json.menu_id,
          menu_name: json.menu_name,
          menu_text: json.menu_text,
          menu_image: json.menu_image,
        });

        this.$router.push({ name: "MenuSelect", query: { menu_id: this.menu_id }})
      })
      .catch( (err) => {
        this.msg = err
        alert('エラーが発生しました。')
        location.reload()
      });
    },
    menuFix() {
      this.updateLoadingOn()
      const params = new URLSearchParams(this.$route.query);
      this.menu_id = params.get('menu_id');
      fetch(apiUrl+'menufix?menuid='+this.menu_id, {credentials: 'include'})
      .then( response => {
        this.updateLoadingOff()
        return response.json()
     })
      .then( json => {
        this.msg = json.message
        this.fix_menu_id = json.menu_id
        this.fix_menu_name = json.menu_name
        this.$router.push({ name: "MenuResult", query: { menu_id: this.menu_id }})
      })
      .catch( (err) => {
        this.msg = err
        alert('エラーが発生しました。')
        location.reload()
      });
    },
  },
  async created () {
    const store = selectMenuStore(); 
    if (store.menu_id != 0) {
      this.menu_id = store.menu_id;
      this.menu_name = store.menu_name;
      this.menu_text = store.menu_text;
      this.menu_image = menuImages(store.menu_image);
      this.createLoaded = true;
    } else {
      try {
        const params = new URLSearchParams(this.$route.query);
        this.menu_id = params.get('menu_id');
        const response = await fetch(apiUrl+`menu?menuid=${this.menu_id}`, { credentials: 'include' });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const json = await response.json();
        if (!json.menu_id || !json.menu_name || !json.menu_text || !json.menu_image) {
          throw new Error('JSON payload is missing required properties.');
        }
        this.menu_id = json.menu_id;
        this.menu_name = json.menu_name;
        this.menu_text = json.menu_text;
        this.menu_image = menuImages(json.menu_image);
        store.$patch({
          menu_id: json.menu_id,
          menu_name: json.menu_name,
          menu_text: json.menu_text,
          menu_image: json.menu_image,
        });
        this.createLoaded = true;
      } catch (error) {
        this.msg = error.message;
        alert('エラーが発生しました。')
        location.reload()
      }
    }
  }
}
</script>
